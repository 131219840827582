<template>
  <span>
    <b-button :href="url"
      v-if="type === 'button'"
    >
      Vai a cartella progetto
    </b-button>
    <b-link class="code" :href="url" v-if="type === 'link'">{{code}}</b-link>
  </span>
</template>

<script>
export default {
  props: {
    project: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      validator: function (value) {
        return ['button', 'link'].includes(value)
      },
      default: 'link'
    },
    isFolderDef: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    code() {
      return this.project.code
    },
    projectYear() {
      return this.project.requestDate ? this.project.requestDate.slice(0, 4) : ''
    },
    url() {
      const defSuffix = this.isFolderDef ? '%5Cdef' : ''
      return `localexplorer:%5C%5CGRAFICA%5CDisegni%5CDisegni%5C${this.projectYear}%5C${this.code + defSuffix}`
    }
  }
}
</script>
